<template>
  <NavBar>
    <!-- WORKSPACE MENU -->
    <template #top>
      <!-- <NavBarWorkspaceButton /> -->
      <button
        class="grid grid-cols-[1.25rem_auto] group items-center gap-3 px-3 py-2 rounded-[4px] w-full h-10 border group duration-300"
        @click="handleClickExit"
      >
        <div class="w-full flex justify-center">
          <AppIcon
            icon="fa-solid fa-chevron-left"
            class="text-surface-500 group-hover:text-surface-700"
          />
        </div>
        <span
          class="text-left text-sm text-surface-800 group-hover:text-surface-900 duration-150 font-medium"
          >System Admin</span
        >
      </button>
    </template>

    <template #nav-items>
      <NavBarGroup>
        <NavBarItem
          label="Users"
          icon="fa-solid fa-users"
          to-name="system-admin-users"
        />
        <NavBarItem
          label="Workspaces"
          icon="fa-solid fa-book-sparkles"
          to-name="system-admin-workspaces"
        />
        <NavBarItem
          label="Simulations"
          icon="fa-solid fa-island-tropical"
          to-name="system-admin-simulations"
        />
        <NavBarItem
          label="Characters"
          icon="fa-solid fa-person-running"
          to-name="system-admin-characters"
        />
      </NavBarGroup>
    </template>
  </NavBar>
</template>

<script setup lang="ts">
const router = useRouter()

function handleClickExit() {
  router.push({ name: 'index' })
}
</script>
